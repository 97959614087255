import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import TaskList from '../employee/TaskList';
import EmployeesList from './EmployeesList';
import { useAuth, returnGrops } from '../../Hooks/UserHooks';

const { TabPane } = Tabs;

const Manage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('tasks');
  const { userProfile } = useAuth();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  let auth = '';
  let jwtToken = '';
  if (userProfile) {
    auth = returnGrops(userProfile);
    jwtToken = userProfile.signInUserSession.accessToken.jwtToken;
  }

  return (
    <div className="manage-page">
      {/* Tabs container */}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
          backgroundColor: '#fff', // Ensure the background matches your app theme
        }}
      >
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          tabBarStyle={{
            display: 'flex',
            width: '100%',
          }}
        >
          <TabPane
            tab={<div style={{ textAlign: 'center', width: '50%' }}>Project/Product</div>}
            key="tasks"
          />
          <TabPane
            tab={<div style={{ textAlign: 'center', width: '50%' }}>Employees List</div>}
            key="employees"
          />
        </Tabs>
      </div>
      <div style={{ paddingTop: '60px' }}> {/* Adjust padding to match Tabs height */}
        {activeTab === 'tasks' && <TaskList auth={auth} jwtToken={jwtToken} isManage={true} />}
        {activeTab === 'employees' && <EmployeesList jwtToken={jwtToken} />}
      </div>
    </div>
  );
};

export default Manage;
