import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    const logout = async () => {
        try {
            await Auth.signOut();
            navigate('/login');
        } catch (error) {
            console.error(error);
        }
    }

    logout();

    return null;
}

export default Logout;