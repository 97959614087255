const API_URL = 'https://z7addc12sl.execute-api.us-east-2.amazonaws.com/v1/dkj'
// const API_URL = 'https://l9nd2oj8q9.execute-api.us-east-2.amazonaws.com/beta/dkj'


export const fetchEmployeesList = async (jwtToken) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  const formdata = new FormData();
  // formdata.append("email_address", email);
  // formdata.append("excel", file);
  // formdata.append("upload_by", userName);

  const url = `${API_URL}/Admin/employees`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  });
  return response.json();
};


export const fetchTimeZoneList = async (jwtToken) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  const formdata = new FormData();

  const url = `${API_URL}/User/timeZone`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  });
  return response.json();
};

export const fetchDepartmentList = async (jwtToken) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  const formdata = new FormData();

  const url = `${API_URL}/Admin/department`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  });
  return response.json();
};

export const fetchManageUser = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Admin/manageEmployee`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};

export const fetchAdminTaskList = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Admin/tasks`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};

export const fetchAdminAddTask = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Admin/addTask`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};

export const fetchEmployeeTaskList = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Employee/Tasks`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};

export const fetchUnclaimedTaskList = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Employee/unclaimedTask`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};

export const fetchTakeTask = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Employee/takeTask`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};


export const fetchEditTask = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Employee/EditTask`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};


export const fetchAdminEditTask = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Admin/editTask`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};

export const fetchAdminInfo = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Admin/info`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};


export const fetchEmployeeInfo = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Employee/info`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};

export const fetchEmployeeTask = async (jwtToken, params) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${jwtToken}`);
  myHeaders.append("Content-Type", "application/json");

  const url = `${API_URL}/Admin/employee_task`;

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params), 
    redirect: "follow",
  });

  return response.json();
};
