import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";


const useAuth = () => {
  const [ loading, setLoading ] = useState(true);
  const [ userProfile, setUserProfile ] = useState<any>(null);

  useEffect(() => {
    const getUser = async () => {
      const res = await Auth.currentAuthenticatedUser();
      return res;
    }

    getUser().then((user) => {
      setUserProfile(user ? user : null);
      setLoading(user ? false : true);
    }).catch(() => {
      setUserProfile(null);
      setLoading(false);
    })
  }, [])

  return { userProfile, loading };
}

const returnGrops = (cognitoUser:any) => {
  const { payload } = cognitoUser.signInUserSession.accessToken;
  const groups = payload['cognito:groups'];
  if (cognitoUser && (groups.indexOf('dkj-admin')) >= 0) {
      return 'dkj-admin';
  } else {
      return 'dkj';
  }

}

export { useAuth,returnGrops };