import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useAuth, returnGrops } from "./Hooks/UserHooks";
import Login from './pages/login'
import HandleLogin from './pages/handleLogin'
import Employee from './pages/employee'
import Logout from './pages/logout'
import Manage from './pages/manage'
import 'antd/dist/reset.css';
import "./App.css";
import { Amplify } from "aws-amplify";
import amplifyconfig from "./amplifyconfiguration.json";
Amplify.configure(amplifyconfig);

const authProtectedRoutes = [
    { path: '/employee', component: Employee },
    { path: '', component: Employee },
    { path: '/', component: Employee },
];

const otherRoutes = [
    { path: '/login', component: Login },
    { path: '/handleLogin', component: HandleLogin },
    { path: '/logout', component: Logout },
]
const adminRoutes = [
    { path: '/manage', component: Manage }]

const AuthProtected = (props: any) => {
    const { userProfile, loading } = useAuth();
    if (loading) return null;

    return userProfile ?
        <>{props.children}</> :
        <Navigate to='/login' state={{ from: props.location }} />
}

const AdminAuthProtected = (props: any) => {
    const { userProfile, loading } = useAuth();
    if (loading) return null;
    let auth = ''
    if(userProfile){
        auth = returnGrops(userProfile)
    }

    return auth === 'dkj-admin' ?
        <>{props.children}</> :
        <Navigate to='/login' state={{ from: props.location }} />
}

const Index = () => {
    return (
        <Router basename="/">

            <Routes>
                {authProtectedRoutes.map((router, idx) => {
                    return <Route key={'authProtected' + idx} path={router.path} element={
                        <AuthProtected><router.component /></AuthProtected>
                    } />
                })}
                {otherRoutes.map((router, idx) => { return <Route key={'otherRoutes' + idx} path={router.path} element={<><router.component /></>} /> })}
                
                {adminRoutes.map((router, idx) => {
                    return <Route key={'authProtected' + idx} path={router.path} element={
                        <AdminAuthProtected><router.component /></AdminAuthProtected>
                    } />
                })}
            </Routes>
        </Router>
    );
};

export default Index;
