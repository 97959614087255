import React, { useState, useEffect } from 'react';
import { Switch, Button, List, Typography, Divider, Input, Drawer, Select, message, Spin, Modal, Tag, Tooltip } from 'antd';
import { DeleteOutlined, ClockCircleOutlined, AppstoreAddOutlined, EditOutlined, SwapOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons'; // For download icon
import { Calendar } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { fetchAdminTaskList, fetchEmployeesList, fetchAdminAddTask, fetchEmployeeTaskList, fetchUnclaimedTaskList, fetchTakeTask, fetchEditTask, fetchAdminEditTask } from '../../fetch/fetch';

const { Text } = Typography;
const { Option } = Select;

const TaskList: React.FC<{ isManage: boolean, auth: string, jwtToken: string, headerSelectedDate?: string, timeZone?: string }> = ({ isManage, auth, jwtToken, headerSelectedDate }) => {
  const [tasks, setTasks] = useState<any>([]);

  const [visible, setVisible] = useState(false);
  const [newTask, setNewTask] = useState({ title: '', hours: '', role: '', comments: '' });
  const [selectedDateRange, setSelectedDateRange] = useState<string>('');  // Updated name
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<any[]>([]);
  const navigate = useNavigate();

  const [addTaskDrawerVisible, setAddTaskDrawerVisible] = useState(false); // For Add Task Drawer
  const [addTaskForm, setAddTaskForm] = useState<any>({ title: '', comments: '', member: [], task_id: '', need_role: "1", tags: [] }); // New task form state
  const [unclaimedTaskList, setUnclaimedTaskList] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const MAX_TAGS = 15;
  const [openTooltip, setOpenTooltip] = useState(null); 


  const handleTooltipToggle = (taskId:any) => {
    setOpenTooltip(openTooltip === taskId ? null : taskId); 
  };


  useEffect(() => {
    if (jwtToken) {
      if (isManage) {
        adminTask()
        fetchEmployeesList(jwtToken).then((res) => {
          if (res.status === "1") {
            setEmployees(res.response);
          } else if (res.status === "-1") {
            navigate('/logout');
          } else {
            setEmployees([]);
            message.error(res.message);
          }
        }).catch((error: any) => {
          setEmployees([]);
          console.log(error);
        });
      }
    }
  }, [jwtToken]);

  useEffect(() => {
    if (!isManage && headerSelectedDate) {
      employeeTaskHandle()
      UnclaimedTaskListHandle()
    }
  }, [headerSelectedDate])

  useEffect(() => {
    const endDate = moment();
    const startDate = moment().subtract(2, 'weeks');
    const formattedRange = `${startDate.format('MM-DD-YYYY')} - ${endDate.format('MM-DD-YYYY')}`;
    setSelectedDateRange(formattedRange);
  }, []);

  const handleCalendarChange = (value: any) => {
    if (value && value.length === 2) {
      const startDate = moment(value[0]);
      const endDate = moment(value[1]);

      const formattedRange = `${startDate.format('MM-DD-YYYY')} - ${endDate.format('MM-DD-YYYY')}`;
      setSelectedDateRange(formattedRange);
    }
  };
  const UnclaimedTaskListHandle = () => {
    let params = {
      search_date: headerSelectedDate
    }
    fetchUnclaimedTaskList(jwtToken, params).then((res) => {
      if (res.status === "1") {
        setUnclaimedTaskList(res.response);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        message.error(res.message);
      }
    }).catch((error: any) => {
      console.log(error);
    });

  }

  const handleInputConfirm = () => {
    if (submitLoading) {
      return
    }

    if (addTaskForm.tags.length >= 15) {
      message.error("You can only add up to 15 Functions.");
      return;
    }

    if (inputValue && addTaskForm.tags.includes(inputValue)) {
      message.error("This Function has already been added.");
      return;
    }

    changeTagsHandle([...addTaskForm.tags, inputValue])
    setInputValue('');
  };
  const handleTagClose = (removedTag: any) => {

    if (submitLoading) {
      return
    }
    changeTagsHandle(addTaskForm.tags.filter((tag: any) => tag !== removedTag))
  };

  const changeTagsHandle = (List: any) => {
    if (isEditing) {
      setSubmitLoading(true);
      const taskPayload = {
        task_id: addTaskForm.task_id,
        roles: List
      };
      fetchAdminEditTask(jwtToken, taskPayload).then((res) => {
        setSubmitLoading(false);
        if (res.status === "1") {
          setTasks((prevTasks: any) =>
            prevTasks.map((item: any) =>
              item.task_id === addTaskForm.task_id
                ? {
                  ...item,
                  roles: addTaskForm.need_role === "1" ? List : [],
                }
                : item
            )
          );
        } else if (res.status === "-1") {
          navigate('/logout');
        } else {
          message.error(res.message);
        }
      }).catch((error: any) => {
        setSubmitLoading(false);
        console.log(error);
      });
    }
    setAddTaskForm({
      ...addTaskForm,
      tags: List,
    });
  }

  const adminTask = () => {
    setLoading(true);
    fetchAdminTaskList(jwtToken).then((res) => {
      setLoading(false);
      if (res.status === "1") {
        const updatedTasks = res.response.map((task: any) => {
          const formattedDate = moment(task.created_ts).format('MM/DD HH:mm:ss');
          return {
            ...task,
            created_ts: formattedDate,
          };
        });

        setTasks(updatedTasks);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        setTasks([]);
        message.error(res.message);
      }
    }).catch((error: any) => {
      setTasks([]);
      setLoading(false);
      console.log(error);
    });
  }

  const employeeTaskHandle = () => {
    setLoading(true);
    let params = {
      search_date: headerSelectedDate
    }
    fetchEmployeeTaskList(jwtToken, params).then((res) => {
      setLoading(false);
      if (res.status === "1") {
        const updatedTasks = res.response.map((task: any) => {
          const formattedDate = moment(task.created_ts).format('MM/DD HH:mm:ss');
          return {
            ...task,
            created_ts: formattedDate,
          };
        });

        setTasks(updatedTasks);
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        setTasks([]);
        message.error(res.message);
      }
    }).catch((error: any) => {
      setTasks([]);
      setLoading(false);
      console.log(error);
    });
  }
  const handleDownload = async () => {
    const [start, end] = selectedDateRange.split(' - ');

    let param = {
      search_start_date: moment(start, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      search_end_date: moment(end, 'MM-DD-YYYY').format('YYYY-MM-DD'),
    };

    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${jwtToken}`);
      myHeaders.append("Content-Type", "application/json");
      const API_URL = 'https://z7addc12sl.execute-api.us-east-2.amazonaws.com/v1/dkj'
      // const API_URL = 'https://l9nd2oj8q9.execute-api.us-east-2.amazonaws.com/beta/dkj'
      const url = `${API_URL}/Admin/download`;

      // Sending a POST request with the parameters
      const response = await fetch(url, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(param),  // Sending parameters as a JSON string
        redirect: "follow",
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error("File download failed");
      }

      // Get the Blob data (file)
      const blob = await response.blob();

      // Create a temporary download link
      const downloadUrl = URL.createObjectURL(blob);

      // Create an <a> element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `Project/Product (${selectedDateRange})`; // Custom file name (you can dynamically set it as needed)
      link.click();  // Trigger the download

      // Clean up the URL object after download
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle errors
      console.error('Error during file download:', error);
    }
  };

  const handleConfirmDateRange = () => {
    setIsDrawerVisible(false);
  };

  const handleDeleteTask = (taskId: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this task?',
      centered: true, // This centers the modal
      okButtonProps: {
        style: {
          backgroundColor: '#00b96b',
          borderColor: '#00b96b',
          color: 'white',
        },
      },
      onOk: () => {
        setLoading(true);
        const taskPayload = {
          task_log_id: taskId,
          status: 'Deleted',
        };
        fetchEditTask(jwtToken, taskPayload).then((res) => {
          setLoading(false);
          if (res.status === '1') {
            message.success(res.message);
            employeeTaskHandle();
          } else {
            message.error(res.message);
          }
        }).catch((error: any) => {
          setLoading(false);
          console.log(error);
        });
      },
      onCancel() {
      },
    });
  };


  const handleAddTask = () => {
    if (!newTask.title || !newTask.hours) {
      alert('All fields are required!');
      return;
    }
    if (!newTask.role) {
      alert('Function is required for the selected project!');
      return;
    }
    setSubmitLoading(true);
    const taskPayload = {
      task_id: newTask.title,
      estimated_hrs: newTask.hours,
      take_date: headerSelectedDate,
      role: newTask.role,
      comment: newTask.comments
    };
    fetchTakeTask(jwtToken, taskPayload).then((res) => {
      setSubmitLoading(false);
      if (res.status === "1") {
        message.success('Add successfully');
        setNewTask({ title: '', hours: '', role: '', comments: '' });
        setVisible(false);
        employeeTaskHandle()
      } else if (res.status === "-1") {
        navigate('/logout');
      } else {
        message.error(res.message);
      }
    }).catch((error: any) => {
      setSubmitLoading(false);
      console.log(error);
    });
  };

  const handleAddNewTask = () => {
    if (!addTaskForm.title) {
      alert('All fields are required!');
      return;
    }

    if (addTaskForm.member.length === 0) {
      message.error('Please select at least one member.');
      return;
    }
    setSubmitLoading(true);
    const taskPayload = {
      title: addTaskForm.title,
      comment: addTaskForm.comments,
      uids: addTaskForm.member,
      need_role: addTaskForm.need_role,
      ...(addTaskForm.task_id && { task_id: addTaskForm.task_id }),
      ...(addTaskForm.need_role === "1" ? { roles: addTaskForm.tags } : { roles: [] })
    };
    if (isEditing) {

      fetchAdminEditTask(jwtToken, taskPayload).then((res) => {
        setSubmitLoading(false);
        if (res.status === "1") {
          message.success(res.message);
          setAddTaskForm({ title: '', comments: '', member: [], task_id: "", need_role: "1", tags: [] });
          setAddTaskDrawerVisible(false);
          setTasks((prevTasks: any) =>
            prevTasks.map((item: any) =>
              item.task_id === addTaskForm.task_id
                ? {
                  ...item,
                  title: addTaskForm.title,
                  comments: addTaskForm.comments,
                  assigned_to: addTaskForm.member.map((uid: any) => ({
                    assigned_to: uid,
                  })),
                  need_role: addTaskForm.need_role,
                  roles: addTaskForm.need_role === "1" ? addTaskForm.tags : [],
                }
                : item
            )
          );
        } else if (res.status === "-1") {
          navigate('/logout');
        } else {
          message.error(res.message);
        }
      }).catch((error: any) => {
        setSubmitLoading(false);
        console.log(error);
      });
    } else {
      fetchAdminAddTask(jwtToken, taskPayload).then((res) => {
        setSubmitLoading(false);
        if (res.status === "1") {
          message.success(res.message);
          setAddTaskForm({ title: '', comments: '', member: [], task_id: "", need_role: "1", tags: [] });
          setAddTaskDrawerVisible(false);
          adminTask()
        } else if (res.status === "-1") {
          navigate('/logout');
        } else {
          message.error(res.message);
        }
      }).catch((error: any) => {
        setSubmitLoading(false);
        console.log(error);
      });

    }
  };
  const EditTaskHandle = (task: any) => {
    const selectedTask = tasks.find(
      (item: any) => item.task_id === task.task_id
    );

    if (selectedTask) {
      setAddTaskForm({
        title: selectedTask.title,
        comments: selectedTask.comments,
        member: selectedTask.assigned_to.map((item: any) => item.assigned_to),
        task_id: selectedTask.task_id,
        need_role: selectedTask.need_role,
        tags: selectedTask.roles ? selectedTask.roles : []
      });
      console.log({
        title: selectedTask.title,
        comments: selectedTask.comments,
        member: selectedTask.assigned_to.map((item: any) => item.assigned_to),
        task_id: selectedTask.task_id,
        need_role: selectedTask.need_role,
        tags: selectedTask.roles ? selectedTask.roles : []
      })
    }
    setAddTaskDrawerVisible(true);
    setIsEditing(true);
  }

  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: '0 auto' }}>
      {/* {isManage && <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <Select
          defaultValue="all"
          style={{ width: 60 }}
          dropdownStyle={{ minWidth: 120 }}
        >
          <Option value="all">All</Option>
          <Option value="completed">Completed</Option>
          <Option value="in-progress">In Progress</Option>
        </Select>
        <Input placeholder="Search tasks" style={{ flex: 1 }} />
        <Button type="primary">Search</Button>
      </div>} */}
      {isManage && <div
        style={{
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
          width: '90vw',
          maxWidth: '600px',
          marginBottom: '20px'
        }}
      >
        <Input
          style={{ width: 250 }}
          value={selectedDateRange}
          readOnly
          placeholder="Start date - End date"
          onClick={() => setIsDrawerVisible(true)} // Open the drawer when clicked
        />

        {/* Download Data Button */}
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={handleDownload}
        >
          Download Data
        </Button>
      </div>}

      {!isManage && (
        <div
          style={{
            textAlign: 'center'
          }}>
          <Button
            type="primary"
            size="large"
            style={{
              width: '90vw',
              maxWidth: '600px',
              marginBottom: '20px',
            }}
            icon={<AppstoreAddOutlined
              style={{
                fontSize: 16,
              }} />}
            onClick={() => setVisible(true)}
          >
            Add Hours
          </Button>
        </div>
      )}
      {loading ? (
        <Spin tip="Loading..." style={{ display: 'block', margin: '100px auto' }} />
      ) :
        <List
          dataSource={tasks}
          renderItem={(task: any) => (
            <div style={{ marginBottom: '10px', padding: '15px', border: '1px solid #ddd', borderRadius: '8px' }}>
              <div style={{ display: 'flex', alignItems: 'start', width: '100%' }}>
                <ClockCircleOutlined style={{ color: 'orange', fontSize: '24px', marginRight: '10px' }} />
                <div style={{ flex: 1 }}>
                  <Text style={{ marginBottom: "10px" }} strong>{task.title.charAt(0).toUpperCase() + task.title.slice(1)}</Text>

                  <div>
                    {!isManage && task.comments && <span
                      title={task.comments}
                      style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Text type="secondary">Comments: </Text>
                      {task.comments}
                    </span>}
                  </div>
                  {/* Conditional rendering for manage vs non-manage */}
                  {isManage ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      {task.comments && <span
                        title={task.comments}
                        style={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Text type="secondary">Description: </Text>
                        {task.comments}
                      </span>}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flexWrap: 'wrap',
                        gap: '8px',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          minWidth: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Text type="secondary">Spent: </Text>
                        <Text
                          type="secondary"
                          style={{
                            marginLeft: 8,
                            fontWeight: '700',
                            color: 'black',
                          }}
                        >
                          {task.estimated_hrs} hrs
                        </Text>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          gap: '8px',
                          flexWrap: 'wrap',
                        }}
                      >
                        <Tooltip
                          title={task.role}
                          open={openTooltip === task.task_log_id}
                          onOpenChange={(visible) => handleTooltipToggle(task.task_log_id)}
                          placement="top"
                        >
                          <Tag
                            style={{
                              marginRight: 0,
                              fontSize: "16px",
                              padding: "3px 8px",
                              maxWidth: "180px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                            }}
                            color="blue"
                          >
                            {task.role}
                          </Tag>
                        </Tooltip>

                        <Tag
                          style={{
                            color: 'gray',
                            borderColor: 'gray',
                            backgroundColor: 'transparent',
                            borderStyle: 'solid',
                            padding: '3px 8px',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleDeleteTask(task.task_log_id)}
                        >
                          Delete
                        </Tag>
                      </div>
                    </div>

                  )}

                  {isManage && <>
                    {/* <Divider style={{ margin: '10px 0' }} /> */}

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>
                        <Text type="secondary">DKJ ID: </Text>
                        <Text>{task.task_id}</Text>
                      </div>

                      {/* Manage Actions */}
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => EditTaskHandle(task)}
                        style={{ padding: 0, marginLeft: 8 }}
                      >
                        Edit
                      </Button>
                    </div></>}
                </div>
              </div>
            </div>
          )}
        />}
      {<div style={{ height: 100 }}></div>}

      {isManage && (<>
        <Button
          size='large'
          type="primary"
          shape="circle"
          icon={<SwapOutlined />}
          style={{
            position: 'fixed',
            bottom: '80px',
            right: '20px',
            fontSize: '24px',
          }}
          onClick={() => {
            navigate('/employee')
          }}
        />
        <Button
          size='large'
          type="primary"
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '80vw',
            maxWidth: '600px',
          }}
          icon={<AppstoreAddOutlined />}
          onClick={() => { setAddTaskDrawerVisible(true); setIsEditing(false) }}
        >
          Add Project/Product
        </Button>

        {/* <Button
          type="primary"
          shape="circle"
          icon={<AppstoreAddOutlined />}
          style={{
            position: 'fixed',
            bottom: '80px',
            right: '20px',
            fontSize: '24px',
            zIndex: 1000
          }}
          onClick={() => setAddTaskDrawerVisible(true)}
        /> */}
      </>)}
      {auth === "dkj-admin" && !isManage && <Button
        type="primary"
        shape="circle"
        size='large'
        icon={<SwapOutlined />}
        style={{
          position: 'fixed',
          bottom: '40px',
          right: '20px',
          fontSize: '24px',
        }}
        onClick={() => {
          navigate('/manage')
        }}
      />}

      {/* Drawer for adding new task */}
      <Drawer
        title="Add Hours"
        width={300}
        placement="bottom"
        closable={true}
        onClose={() => { if (!submitLoading) { setVisible(false) } }}
        visible={visible}
        height="auto"
      >
        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Project/Product <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            placeholder="Select Task"
            value={newTask.title}
            onChange={(e) => {
              const selectedTask = unclaimedTaskList.find((task) => task.task_id === e);
              setNewTask({
                ...newTask,
                title: e,
                role: '',
              });
              setRoles(selectedTask?.roles || []);
            }}
            style={{ width: '100%' }}
          >
            {unclaimedTaskList.map((task, index) => (
              <Option key={index} value={task.task_id}>
                {task.title}
              </Option>
            ))}
          </Select>

        </div>

        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Function <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            placeholder="Select Function Type"
            value={newTask.role}
            onChange={(value) => setNewTask({ ...newTask, role: value })}
            style={{ width: '100%' }}
          >
            {roles && roles.length > 0 ? (
              roles.map((item: any) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))
            ) : (
              <>
                <Option value="PM">PM</Option>
                <Option value="BA">BA</Option>
                <Option value="DEV">DEV</Option>
                <Option value="QA">QA</Option>
                <Option value="Meeting">Meeting</Option>
                <Option value="Others">Others</Option>
              </>
            )}
          </Select>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Hours <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              inputMode="decimal"
              pattern="[0-9.]*"
              placeholder="0"
              value={newTask.hours}
              onChange={(e) => setNewTask({ ...newTask, hours: e.target.value })}
              style={{ maxWidth: '100px' }}  // Adjust the width
            />
            <Text type="secondary" style={{ marginLeft: 8 }}>hrs</Text>
          </div>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Comments
          </label>
          <Input.TextArea
            placeholder="Comments"
            value={newTask.comments}
            onChange={(e) => setNewTask({ ...newTask, comments: e.target.value })}
            rows={3}
          />
        </div>

        <Button type="primary" onClick={handleAddTask} style={{ width: '100%' }}
          loading={submitLoading}
          size='large'
          icon={submitLoading ? <Spin size="small" /> : null}>
          Add
        </Button>
      </Drawer>


      <Drawer
        title={isEditing ? 'Edit Project/Product' : 'Add Project/Product'}
        width={300}
        placement="bottom"
        closable={true}
        onClose={() => {
          if (!submitLoading) {
            setAddTaskDrawerVisible(false); setAddTaskForm({ title: '', comments: '', member: [], task_id: "", need_role: "1", tags: [] });
            setInputValue('');
          }
        }}
        visible={addTaskDrawerVisible}
        height="auto"
        bodyStyle={{
          maxHeight: '88vh',
          overflowY: 'auto',
        }}
      >
        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Project/Product <span style={{ color: 'red' }}>*</span>
          </label>
          <Input
            placeholder="Project/Product"
            value={addTaskForm.title}
            onChange={(e) => setAddTaskForm({ ...addTaskForm, title: e.target.value })}
          />
        </div>

        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Description
          </label>
          <Input.TextArea
            placeholder="Comments"
            value={addTaskForm.comments}
            onChange={(e) => setAddTaskForm({ ...addTaskForm, comments: e.target.value })}
            rows={3}
          />
        </div>


        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Function
          </label>
          <Switch
            checked={addTaskForm.need_role === '1'}  // Switch is checked if need_role is '1'
            onChange={e => { setAddTaskForm({ ...addTaskForm, need_role: e ? '1' : '0' }) }}
          />
        </div>

        {addTaskForm.need_role === '1' && (
          <div style={{
            border: '1px solid #d9d9d9',
            borderRadius: '4px',
            padding: '4px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            marginBottom: 4,
          }}>
            {addTaskForm.tags.map((tag: any) => (
              <Tag
                key={tag}
                closable
                onClose={() => handleTagClose(tag)}
                style={{ marginBottom: '4px' }}
              >
                {tag}
              </Tag>
            ))}
            <Input
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onPressEnter={handleInputConfirm}
              placeholder="Add Custom Function"
              style={{
                flex: '1',
                minWidth: '100px',
                marginBottom: '4px',
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
              }}
            />
          </div>
        )}
        <div style={{ marginBottom: '16px' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Member <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            mode="multiple"
            placeholder="Select Assigned Members"
            value={addTaskForm.member}
            onChange={(e) => setAddTaskForm({ ...addTaskForm, member: e })}
            style={{
              width: '100%',
              // maxHeight: '100px', 
              // overflowY: 'auto',
            }}
            maxTagCount={10}
            showSearch={false}
            dropdownRender={(menu) => <div>{menu}</div>}
          >
            {employees.map((employee, index) => (
              <Option key={index} value={employee.uid}>
                {employee.name}
              </Option>
            ))}
          </Select>
        </div>


        {/* Add Switch component for 'Disabled Function' */}

        <Button
          type="primary"
          onClick={handleAddNewTask}
          style={{ width: '100%' }}
          size='large'
          loading={submitLoading}  // Apply the loading state
          icon={submitLoading ? <Spin size="small" /> : null}
        >
          {isEditing ? 'Update' : 'Add'}
        </Button>
      </Drawer>

      {/* Drawer for selecting date range */}
      <Drawer
        title="Select Date Range"
        width={400}
        placement="right"
        closable={true}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
      >
        <Calendar
          selectionMode="range" // Enable range selection
          onChange={handleCalendarChange}
          max={today}
        />
        <Button
          size='large' type="primary" onClick={handleConfirmDateRange} style={{ marginTop: 10 }}>
          Confirm
        </Button>
      </Drawer>
    </div>
  );
};

export default TaskList;
