import React, { useState, useEffect } from 'react';
// import { Spin, message } from 'antd';
import DateNavigationHeader from './DateNavigationHeader';
import TaskList from './TaskList';
import { useAuth, returnGrops } from '../../Hooks/UserHooks';
// import { fetchEmployeeInfo, fetchAdminInfo } from '../../fetch/fetch';
// import { useNavigate } from 'react-router-dom';

const RankList: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState('');
  // const [loadingSCC, setLoading] = useState(true);
  // const [timeZone, setTimeZone] = useState<string>(''); 
  const { userProfile } = useAuth();
  // const navigate = useNavigate();
  let jwtToken = '';
  let auth = '';

  if (userProfile) {
    auth = returnGrops(userProfile);
    jwtToken = userProfile.signInUserSession.accessToken.jwtToken;
  }
  // console.log(userProfile)

  // useEffect(() => {
  //   if (jwtToken) {
  //     setLoading(true);

  //     const fetchData = async () => {
  //       try {
  //         // If auth is "dkj-admin", use fetchAdminInfo instead of fetchEmployeeInfo
  //         const response = auth === 'dkj-admin'
  //           ? await fetchAdminInfo(jwtToken)
  //           : await fetchEmployeeInfo(jwtToken);

  //         if (response.status === "0") {
  //           message.error(response.message || "Something went wrong");
  //         } else if (response.status === "-1") {
  //           navigate('/logout');
  //         } else if (response.status === "1") {
  //           const timeZone = response.response.time_zone.split(" ")[1];
  //           console.log(timeZone,'timeZone')
  //           setTimeZone(timeZone);
  //         }
  //       } catch (error) {
  //         message.error("Error fetching data",);
  //         console.error("Error fetching data", error);
  //         setLoading(false);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [jwtToken, auth, navigate]); // Include timeZone in dependency array

  const handleDateChange = (date: string) => {
    setSelectedDate(date);
  };

  return (
    <div>
      {/* {(loadingSCC || !timeZone) ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      ) : ( */}
        <>
          <DateNavigationHeader onDateChange={handleDateChange} disabled={false} />
          <TaskList auth={auth} headerSelectedDate={selectedDate} jwtToken={jwtToken} isManage={false} />
        </>
    </div>
  );
};

export default RankList;
